import PropTypes from 'prop-types';
import React from 'react';

export default function DownloadsList({ data }) {
  return (
    <ul>
      {data.map((download) => (
        <li key={download.id}>
          <a
            href={download.file.url}
            download={download.title}
            target="_blank"
            rel="noopener noreferrer"
          >
            {download.title}
          </a>
        </li>
      ))}
    </ul>
  );
}

DownloadsList.propTypes = {
  data: PropTypes.array.isRequired,
};
