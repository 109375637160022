import React from 'react';
import DownloadsList from '../../components/DownloadsList';
import Footer from '../../components/Footer';
import Layout from '../../components/Layout';
import Navigation from '../../components/Navigation';
import { Container } from './styles';

const now = new Date();

function getVisibleDownloads(downloads) {
  const allVisibleDownloads = downloads.filter(({ availableUntil }) =>
    availableUntil ? now < new Date(availableUntil) : true
  );

  return allVisibleDownloads.length ? allVisibleDownloads : null;
}

export default function Downloads({
  pageContext: { metadata, navigation, footer, downloads },
}) {
  const visibleDownloads = getVisibleDownloads(downloads);

  return (
    <Layout metadata={metadata}>
      <Navigation data={navigation.data} logo={navigation.logo} />

      <Container>
        <h1>Downloads</h1>
        {visibleDownloads ? (
          <DownloadsList data={visibleDownloads} />
        ) : (
          <p>Inga downloads finns att ladda ner just nu.</p>
        )}
      </Container>

      <Footer data={footer} />
    </Layout>
  );
}
